/*=========================================================================================
    File Name: search.scss
    Description: Search  functionality.
    ----------------------------------------------------------------------------------------
    Item Name: Chameleon Admin - Modern Bootstrap 4 WebApp & Dashboard HTML Template + UI Kit
    Version: 1.2
    Author: ThemeSelection
    Author URL: https://themeselection.com/
==========================================================================================*/

.navbar-container{

    a.nav-link-search{
        float: left;
    }
    .search-input{
        float: left;
        padding-top: 1.2rem;
        width: 0;
        .input{
            width:0;            
            border: none;
            background: none;
            transition: all 0.2s ease-out;
            line-height: 16px;
        }
        &.open{
            .input{
                width: 200px;
                padding:5px 10px;                       
                outline: none;
                background: none;               
                transition: all 0.3s ease-out;
            }
        }
    }
}
.navbar-light, .navbar-semi-dark{
    .search-input{
        .input{
            color: #2C303B;
            &::placeholder{
                color: #2C303B;
            }
        }
        &.open{
            .input{
                color: #2C303B;
                border-bottom: 1px solid #2C303B;
                
            }
        }
    }
}
.navbar-dark, .navbar-semi-light{
    .search-input{
        .input{
            color: #F9FAFD;
            &::placeholder{
                color: #F9FAFD;
            }
        }
        &.open{
            .input{
                color: #F9FAFD;
                border-bottom: 1px solid #F9FAFD;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    #navbar-mobile{
        .search-input.open{
            .input{
                width: 180px;
                color: #2C303B;
                border-bottom: 1px solid #2C303B;
                overflow: hidden;
                position: relative;
                background: #fff;
                z-index: 1;
                padding: 10px 10px;
                top: -5px;
            }
        }
    }
}