#cookie-warning {
    position: fixed;
    z-index: 9999;
    bottom: 30px;
    left: 30px;
    padding: 25px;
    background: #fff;
    width: 90%;
    max-width: 450px;
    text-transform: none;
    box-shadow: 3px 3px 8px 0 #35495e;
}

