.chart-container {
  position: relative;
  width: 100%;
}
@media (max-width: 768px) {
  .chart-container {
    max-width: 100%;
  }
}
.chart {
  position: relative;
  display: block;
  width: 100%;
}

.jqstooltip{
  box-sizing: content-box;
}