
    div#start-scroll {
        width: auto;
        position: fixed;
        z-index: 5;
    }
    div#start {
        width: auto;
        position: fixed;
        padding: 10px 10px;
        position: relative;
        z-index: 1;
    }

    .multiselect.form-control.multiselect {
        padding: 0;
    }

    #div1 {
        display: none;
    }

    .dv-order-tabs__tab.active {
        color: #fff;
        background-color: #9a79fd !important;
    }

    .dv-order-tabs__tab.active span.dv-order-tabs__tab-label {
        color: aliceblue;
    }

    /*в самом вверху табы пешком авто*/
    .dv-order-tabs__tab {
        display: block;
        padding: 0.5rem 1rem;
        border: 1px solid #e4d9cd;
        /*border-radius: 4px;*/
    }

    .dv-order-tabs .horizontal {
        flex-direction: row;
        border: 1px solid #e4d9cd;
        overflow-x: auto;
    }

    .dv-order-tabs.horizontal .dv-order-tabs__tab {
        border-bottom: none;
        border-right: 1px solid #d2c0ac;
        flex-grow: inherit;
    }

    .dv-order-tabs__tab-label .active {
        color: inherit;
        font-weight: 600;
        border-bottom: none;
    }

    @media (max-width: 768px) {
        .dv-order-tabs__tab-label {
            font-size: 14px;
        }
    }

    .dv-order-tabs__tab-label {
        color: #fa626b;
        font-size: 15px;
        cursor: pointer;
        text-decoration: none;
        white-space: nowrap;
    }

    /*способ оплаты*/
    .dv-order-payment__list {
        width: 100%;
        /* display: flex;*/
        align-items: center;
    }

    .dv-order-payment__option--active {
        border: solid 1px #e46799;
    }

    .dv-order-payment__option {
        margin-right: 14px;
        width: 250px;
        height: 60px;
        border-radius: 4px;
        background-color: #ffffff;
        border: solid 1px #cccccc;
        cursor: pointer;
    }

    .dv-order-payment__image {
        width: 32px;
        height: 32px;
        margin: 14px 12px 0 16px;
        float: left;
    }

    .dv-order-payment__label {
        margin: 13px 0 0 0;
        line-height: 1.2;
        font-size: 15px;
    }

    .dv-order-payment__description {
        margin: 0;
        line-height: 1.8;
        font-size: 10px;
        color: #333c4e;
    }

    .dv-order-payment__option:last-child {
        margin-right: 0;
    }

    .dv-order-payment__option.active, .dv-order-image-tabs__tab.active {
        border-style: solid;
        border-width: 1px;
        border-color: #9a79fd;
    }

    /*картинки в ряд*/
    @media (max-width: 768px) {
        .order-form__row {
            padding-right: 20px;
            padding-left: 20px;
        }
    }

    .order-form__row {
        margin-top: 16px;
        padding: 0 24px 0 144px;
        display: flex;
        flex-wrap: wrap;
    }

    .dv-order-tabs.horizontal {
        flex-direction: row;
        border: 1px solid #e4d9cd;
        overflow-x: auto;
    }

    .dv-order-tabs {
        display: inline-flex;
        scrollbar-width: none;
    }

    .dv-order-image-tabs {
        display: flex;
        overflow-x: scroll;
        width: 100%;
    }

    form, div, span, object, iframe, img, table, caption, thead, tbody, tfoot, tr, tr, td, article, aside, canvas, details, figure, hgroup, menu, nav, footer, header, section, summary, mark, audio, video {
        border: 0;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .dv-order-image-tabs__tab--active {
        border-radius: 5px;
        border-color: #e05a90;
    }

    .dv-order-image-tabs__tab {
        margin-top: 0;
        cursor: pointer;
        flex: 0 1 170px;
        margin-right: 12px;
        padding: 16px 12px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        min-width: 185px;
        max-width: 185px;
        height: 72px;
        border-radius: 3px;
        border: 1px solid #f4f4f4;
        background: #ffffff;
        overflow: hidden;
    }

    .dv-order-image-tabs__icon {
        display: flex;
        height: 28px;
        width: 44px;
        align-items: center;
        justify-content: center;
        min-height: inherit;
    }

    .dv-order-image-tabs__description {
        width: calc(100% - 56px);
        line-height: 24px;
    }

    form, div, span, object, iframe, img, table, caption, thead, tbody, tfoot, tr, tr, td, article, aside, canvas, details, figure, hgroup, menu, nav, footer, header, section, summary, mark, audio, video {
        border: 0;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .dv-order-image-tabs__icon img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    .dv-order-image-tabs__description span {
        color: #333c4e;
        font-size: 13px;
        line-height: normal;
        font-weight: normal;
    }

    .dv-order-image-tabs__description p {
        color: #333c4e;
        font-size: 13px;
        line-height: normal;
        font-weight: normal;
        white-space: nowrap;
        margin: 0;
    }
