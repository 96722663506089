body.vertical-layout.vertical-menu.menu-expanded .main-menu .navigation > li > a > i:before {
    font-size: 1rem;
}

body.vertical-layout.vertical-menu.menu-expanded .main-menu.menu-light .navigation > li > a > i {
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.navbar-header .navbar-brand .brand-logo {
    width: 50px;
}

.avatar-text {
    line-height: 1.5 !important;
    padding-top: 3px;
    font-size: 12px;
}

.menu-text {
    line-height: 1.5 !important;
    font-size: 12px !important;
}

@include media-breakpoint-down(xl) {
    .header-navbar .navbar-container ul.nav li a.dropdown-user-link {
        padding: 23px 0.6rem !important;
    }
}


@include media-breakpoint-up(xs) {
    .header-navbar .navbar-container ul.nav li a.dropdown-user-link {
        padding: .8rem .6rem !important;
    }
    .header-navbar.navbar-with-menu .navbar-container {
        height: 6rem;
    }
}

@include media-breakpoint-up(lg) {
    a.dropdown-toggle.nav-link.menu-text.text_one {
        width: 270px;
    }
    a.dropdown-toggle.nav-link.menu-text.text_three {
        width: 340px;
    }
    .dropdown-toggle {
        white-space: initial;
    }
    //.header-navbar .navbar-container ul.nav li > a.nav-link {//уменьшила отступ в шапке
    //    padding: 0.7rem 0.1rem !important;
    //}
}

@include media-breakpoint-up(xl) {

    .header-navbar .navbar-container ul.nav li i.ficon {
        margin-right: 15px;
    }
    .menu-text {
        font-size: 15px !important;
    }
    .dropdown-toggle {
        white-space: initial;
    }
    a.dropdown-toggle.nav-link.menu-text.text_one {
        width: 270px;
        padding-top: 20px;
    }
    a.dropdown-toggle.nav-link.menu-text.text_three {
        width: 340px;
        padding-top: 20px;
    }
    .header-navbar .navbar-container {
        height: 7rem;
    }
    a.btn.btn-bg-gradient-x-purple-blue.btn-lg.btn-block.btn-calculator {
        white-space: nowrap;
        padding: 20px 5px;

    }
    .header-navbar.navbar-with-menu .navbar-container {
        height: 7rem;
    }
}

@media (min-width: 1600px) {
    .header-navbar .navbar-container ul.nav li > a.nav-link {
        padding: 0.7rem 0.45rem !important;
    }
    .menu-text {
        font-size: 16px !important;
    }
    .navbar-semi-light .navbar-nav > li {
        padding: 0 10px;
    }
    .header-navbar .navbar-container ul.nav li i.ficon {
        margin-top: 10px;
        margin-right: 0;
        font-size: 2rem;
    }
    a.dropdown-toggle.nav-link.menu-text.text_one {
        width: 350px;
    }
    a.dropdown-toggle.nav-link.menu-text.text_three {
        width: 490px;
    }
}

@media (min-width: 1920px) {
    a.dropdown-toggle.nav-link.menu-text.text_one {
        width: auto;
    }
    a.dropdown-toggle.nav-link.menu-text.text_three {
        width: 490px;
    }
    html body.fixed-navbar {
        padding-top: 8rem;
    }
    .header-navbar.navbar-with-menu .navbar-container {
        height: 8rem;
    }
}

a.dropdown-item.line-height-1half.d-xl-none.nowrap-none {
    white-space: normal;
}

//колокольчик
@include media-breakpoint-down(xs) {
    .header-navbar .navbar-container ul.nav li.nav-item i {
        margin-right: 0;
    }
    .header-navbar .navbar-container ul.nav li a.nav-link-label {
        padding: 1.9rem 1rem;
    }
}

@include media-breakpoint-up(md) {
    .header-navbar .navbar-container ul.nav li.nav-item i {
        margin-right: 0.5rem;
    }
    .badge-up {
        top: 10px;
    }
}

@include media-breakpoint-up(lg) {
    .badge-up {
        top: 10px;
    }
}

@include media-breakpoint-up(custom1600) {
    .badge-up {
        right: 20px;
    }
}
.arrow_box_right { //перенос строк, если не помещается текст в колокольчике
    word-break: break-word;
}
//login and register menu
nav.header-navbar.navbar-expand-md.navbar.navbar-with-menu.navbar-without-dd-arrow.fixed-top.navbar-semi-light.background-none {
    background: none;
}

.background-none.content.navbar-container {
    background: none;
}
//не выделять в раскрывающейся аватарке при переходе и наведении

.dropdown-item.no-bg:hover, .dropdown-item.no-bg:focus {
    color: #2A2E30 !important;
    text-decoration: none;
    background-color: white; }
.dropdown-item.no-bg.active, .dropdown-item.no-bg:active {
    color: #2A2E30 !important;
    text-decoration: none;
    background-color: white; }
.dropdown-item.no-bg.disabled, .dropdown-item.no-bg:disabled {
    color: #2A2E30 !important;
    pointer-events: none;
    background-color: white; }
.dropdown-item.no-bg{
    color: #2A2E30 !important;
}
