@import './chameleon/bootstrap.scss';
@import './chameleon/bootstrap-extended.scss';
@import './chameleon/components.scss';
@import './chameleon/plugins/forms/checkboxes-radios.scss';
@import './chameleon/plugins/forms/switch.scss';
@import './chameleon/core/menu/menu-types/vertical-menu.scss';
@import './chameleon/colors.scss';
@import './chameleon/core/colors/palette-gradient.scss';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '../vendors/css/vendors.min.css';
//@import '../vendors/css/extensions/pace.css';
@import '../vendors/css/forms/icheck/flat/_all.css';
@import '../vendors/css/forms/toggle/switchery.css';
@import '../vendors/css/timeline/vertical-timeline.css';
@import './custom.scss';
@import './calculator.scss';
@import './menu.scss';
@import './spinner.scss';
@import './validation.scss';
@import './dostavista.scss';
@import './cookie.scss';



