[v-cloak] {
    display: none;
}
//добавила правому элементу больше места, посмотреть можно на orders/show
@include media-breakpoint-up(xl){
    .sidebar-left{
        float:left;
    }
    .sidebar-right{
        float:right;
    }
}
@include media-breakpoint-up(xl) {
    body .content-detached.content-left .content-body {
        margin-right: 420px;
    }
    body .content-detached.content-left {
        float: left;
        margin-right: -400px;
    }
    .sidebar {
        vertical-align: top;
        width: 400px;
    }
}
@include media-breakpoint-up(custom1920) {
    body .content-detached.content-left .content-body {
        margin-right: 520px;
    }
    body .content-detached.content-left {
        float: left;
        margin-right: -500px;
    }
    .sidebar {
        vertical-align: top;
        width: 500px;
    }
}

//карточке задала высоту относительно ширины
@media (min-width: 1200px) {
    .calculator__container_main {
        height: 55vh;
    }
}
.w-50 {
    width: 50% !important;
}

.pointer {
    cursor: pointer;
}
.nowrap {
    white-space: nowrap;
}
.line-height-1half {
    line-height: 1.5 !important;
}
.border-tariffs {
    border: 1px solid #4db6ac4f;
    margin: 10px 0px;
    padding: 10px;
    background: #4db6ac21;
}

.modal {
    z-index: 10000;
}

.px-12 {
    padding: 0px 12px;
}

.px-7 {
    padding: 0px 7px;
}
.height-75vh{
    height: 75vh;
}
@include media-breakpoint-up(md) {
    .btn-small {
        width: 120px;
    }
}
.table th, .table td {
    padding: 0.75rem 1rem !important;
}

.table-roles th, .table-roles td {
    padding: 0.3rem 1rem !important;
    vertical-align: sub;
}
tbody > tr > .pb-none {
    padding-bottom: 0px !important;
    padding-top: 20px !important;
}

tbody > tr > .pt-none {
    padding-top: 0px !important;
}

.text-weight-800 {
    font-weight: bold;
}
.btn-sm,
.btn-sm:active,
.btn-sm:focus {
    outline: none !important;
}
.btn-xs, .btn-group-xs > .btn {
    padding: 0.1rem 0.1rem;
    font-size: 0.1rem;
    line-height: 1;
    border-radius: 0.2rem;
    border: 0;
    color: #8785fd;
    background: white;
}
.table-color .table th, .table-color .table td {
    border-top: 1px solid black !important;
    border-left: 1px solid black !important;
    border-right: 1px solid black !important;
    border-bottom: 1px solid black !important;
}
.border_default {
    border: 1px solid #BABFC7;
}
.activity-timeline .act-time {
    top: 5px !important;
    left: -140px;
    text-transform: uppercase;
    font-size: 12px !important;
    font-weight: 700;
}

.activity-timeline li .timeline-icon {
    top: 0;
    left: -3.5rem;
    width: 32px !important;
    height: 32px !important;
    text-align: center !important;
    color: #fff;
    border-radius: 50% !important;
    background: #c6c5c7;
    font-size: 8px;
    font-weight: 700;
    line-height: 39px;
}

.timeline .activity-timeline {
    margin-left: 110px;
    padding-left: 32px;
}
.btn-bg-gradient-x-purple-blue, .btn-bg-gradient-x-blue-cyan:active, .btn-bg-gradient-x-blue-cyan:hover {
    color: white !important;
}
.tooltip-inner {
    color: #fff !important;
}
.modal-scroll {
    height: 480px !important;
    overflow: scroll !important;
}

.modal-backdrop {
    opacity: 0.5;
}
.btn-info {
    color: white !important;
}

.user-name {
    vertical-align: super;
}
.btn-bg-gradient-x-purple-blue .btn-lg .fix-height {
    padding: 20px 10px !important;
}
.row-all-width {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -7px;
    width: 100%;
}

html body .la {
    font-size: 1rem;
}

button.btn {
    text-transform: uppercase !important;
}
html body {
    text-transform: uppercase !important;//сделала заглавными
}
.multiselect__placeholder{
    text-transform: capitalize;
}
.table-time th, .table-time td {
    padding: 0.3rem 0.5rem !important;
    vertical-align: sub;
}
.table-time .has-icon-left .form-control {
    padding-right: 0;
    padding-left: 2.5rem;
}
table.table-time{
    width: 100%;
}
.table-time tr {
    text-align: center;
}
.successfully{
    font-size: 90px;
    color: #2cb72c73;
}
.unsuccessfully{
    font-size: 90px;
    color: #ff2e2e9c;
}
.badge-my{
    border: 1px solid #f0efef;
    background: #e6e6e6;
    border-radius: 10px;
    color: #606060;
    font-weight: 700;
    font-size: 12px;
    padding: 2px 5px;
    width: max-content;
    max-width: 90%;
}
.input-min{
    padding: 0;
    width: auto;
}
.password-sent{
    white-space: normal;
    line-height: 1.5;
    font-size: 13px;
}
.pr-10{
    padding-right: 10px;
}
a .btn{
    border-radius: 0.25rem;
}

.width1{
    width: 10px;
}
.padding1{
   padding: 10px;
}
.height1{
    height: 10px;
}

.form-group label .vertical-middle{
    vertical-align: middle;
}
.form-group label span {
    padding-top: 2px;
}
.overflow-inherit{
    overflow: inherit !important;
}

.pl-7{
    padding-left: 7px;
}
.pr-7{
    padding-right: 7px;
}
.mt-7{
    margin-top: 7px;
}
//в регистрации свойство для tabs
.nav.nav-tabs .nav-item .nav-link {
    display: block;
}
.blank-page .content-wrapper .flexbox-container{
    align-items: center;
    margin: 70px auto;
    height: auto !important;
}
.px-8{
    padding-left: 8px;
    padding-right: 8px;
}
@include media-breakpoint-up(lg) {
    .width-lg-90vh {
        width: 90vh !important;
    }
    .max-width-lg-90vh {
        max-width: 90vh !important;
    }
}
//setting checkbox
$lightGrey: #99A3BA;
.switch {
    height: 20px;
    display: block;
    position: relative;
    cursor: pointer;
    input {
        display: none;
        & + span {
            padding-left: 50px;
            min-height: 20px;
            line-height: 20px;
            display: block;
            position: relative;
            vertical-align: middle;
            white-space: nowrap;
            transition: color .3s ease;
            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                border-radius: 12px;
            }
            &:before {
                top: 0;
                left: 0;
                width: 42px;
                height: 24px;
                background: #E4ECFA;
                transition: all .3s ease;
            }
            &:after {
                width: 18px;
                height: 18px;
                background: #fff;
                top: 3px;
                left: 3px;
                box-shadow: 0 1px 3px rgba(#121621, .1);
                transition: all .45s ease;
            }
            em {
                width: 8px;
                height: 7px;
                background: $lightGrey;
                position: absolute;
                left: 8px;
                bottom: 7px;
                border-radius: 2px;
                display: block;
                z-index: 1;
                transition: all .45s ease;
                &:before {
                    content: '';
                    width: 2px;
                    height: 2px;
                    border-radius: 1px;
                    background: #fff;
                    position: absolute;
                    display: block;
                    left: 50%;
                    top: 50%;
                    margin: -1px 0 0 -1px;
                }
                &:after {
                    content: '';
                    display: block;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    border: 1px solid $lightGrey;
                    border-bottom: 0;
                    width: 6px;
                    height: 4px;
                    left: 1px;
                    bottom: 6px;
                    position: absolute;
                    z-index: 1;
                    transform-origin: 0 100%;
                    transition: all .45s ease;
                    transform: rotate(-35deg) translate(0, 1px);
                }
            }
            strong {
                font-weight: normal;
                position: relative;
                display: block;
                top: 1px;
                &:before,
                &:after {
                    font-size: 14px;
                    font-weight: 500;
                    display: block;
                    font-family: 'Mukta Malar', Arial;
                    -webkit-backface-visibility: hidden;
                }
                &:before {
                    transition: all .3s ease .2s;
                }
                &:after {
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    left: 0;
                    top: 0;
                    color: rgb(117, 199, 145);
                    transition: all .3s ease;
                    transform: translate(2px, 0);
                }
            }
        }
        &:checked {
            & + span {
                &:before {
                    background: rgba(rgb(117, 199, 145), .9);
                }
                &:after {
                    background: #fff;
                    transform: translate(18px, 0);
                }
                em {
                    transform: translate(18px, 0);
                    background: rgb(117, 199, 145);
                    &:after {
                        border-color: rgb(117, 199, 145);
                        transform: rotate(0deg) translate(0, 0);
                    }
                }
                strong {
                    &:before {
                        opacity: 0;
                        visibility: hidden;
                        transition: all .3s ease;
                        transform: translate(-2px, 0);
                    }
                    &:after {
                        opacity: 1;
                        visibility: visible;
                        transform: translate(0, 0);
                        transition: all .3s ease .2s;
                    }
                }
            }
        }
    }
}

.has-icon-left .form-control.vue-tel-input {
    padding-left: 2px;
}

.vti__input {
    text-transform: uppercase;

     // Placeholder
    &::placeholder {
        color: $input-placeholder-color;
        // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
        opacity: 1;
    }
}

.vti__dropdown-list.above, .vti__dropdown-list.below {
    z-index: 10;
}

.table__with_return {
    margin-top: 2px;
    padding: 2px 5px;
    background: #fa626b;
    color: rgb(255, 255, 255);
    border-radius: 5px;
    display: inline-flex;
    white-space: nowrap;
}
