/* the loading spinner */
.spinner-front, .spinner-back {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    width: 100%;
}
.menu-expanded .spinner-front, .menu-collapsed .spinner-front, .menu-hide .spinner-front{
    left: 0;
    position: absolute;
    z-index: 999;
    color: #fff;
    text-align: center;
    transition: 300ms ease all;
    margin-top: 42vh;
}
.spinner-back {
    z-index: 998;
    height: 100vh;
    background: #000;
}

.spinner-front.show {
    visibility: visible;
    opacity: 1;
}

.spinner-back.show {
    visibility: visible;
    opacity: 0.7;
}

.spinner-front {
    position: fixed !important;
    margin-top: 42vh !important;
}

.menu-expanded .spinner-front {
    left: 8% !important;
}
// End
