/*=========================================================================================
    File Name: sidebar.scss
    Description: content sidebar specific scss.
    ----------------------------------------------------------------------------------------
    Item Name: Chameleon Admin - Modern Bootstrap 4 WebApp & Dashboard HTML Template + UI Kit
    Version: 1.2
    Author: ThemeSelection
    Author URL: https://themeselection.com/
==========================================================================================*/



// Sidebar base
// ------------------------------

// Base
.sidebar {
    position: relative;
    width: 100%;
    z-index: 9;

    // For desktop only
    @include media-breakpoint-up(lg){
        // display: table-cell;
        vertical-align: top;
        width: $sidebar-width;
    }   
}
.sidebar-fixed{
    position: fixed;
    height: 100%;
    overflow: scroll;
}
@include media-breakpoint-up(lg){
    .sidebar-left{
        float:left;
    }
    .sidebar-right{
        float:right;
    }
}