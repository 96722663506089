.breadcrumb {
    font-size:1rem;
    font-family: $font-family-monospace;
    background-color: $transparent;
    padding: 0 0 $breadcrumb-padding-y $breadcrumb-padding-x;
    > li {
        + li::before {
              padding-right: .6rem;
              padding-left: .6rem;
    }
  }
  .breadcrumb-item{
    &.active{
      color: $white;
    }
    a{
      color: rgba($white, 0.8);
      &:hover{
        color: $white;
      }
    }
  }
  .breadcrumb-item + .breadcrumb-item{
    padding-left: 0;
    &::before{
        content: "-";
    }
  }
}
/* Custom scss for page headers style */
.breadcrumbs-right{
	.breadcrumb {
		float:right;
	}
}
.breadcrumbs-top{
	.breadcrumb {
		margin: 0;
		padding: 0;
	}
}

.breadcrumb-new{
    .content-header-title{
        padding: 6px 24px 8px 0;
        margin: 0 24px 0 0;
        border-right: 1px solid rgba($white,0.3);
    }
    .content-header-right{
        
    }
}
@include media-breakpoint-down(sm) {
  .breadcrumb {
    font-size:0.85rem;
  }
  .breadcrumbs-top{
    .breadcrumb {
      padding-bottom: 0.5rem;
    }
  }
}