
.notification-style {
    padding: 10px;
    margin: 0 5px 5px;

    font-size: 12px;

    background: #44A4FC;
    border-left: 5px solid #187FE7;

    &.warn {
        color: #ffffff !important;
        background: #ffb648;
        border-left-color: #f48a06;
    }

    &.error {
        color: #ffffff !important;
        background: #E54D42;
        border-left-color: #B82E24;
    }

    &.success {
        color: #ffffff !important;
        background: #68CD86;
        border-left-color: #42A85F;
    }
}

.form-group .validated-error {
    border-color: red;
}
