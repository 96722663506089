td.day{
    color:#d67676 !important;
}

td.old, td.disabled{
    color:#C1C1C1 !important;
}

td.active {
    color:#fff !important;
}

.border-tariffs .form-control {
    padding-top: 5px !important;
    padding-bottom: 0px !important;
}

.input-xl .fix-height {
    padding-top: 29px !important;
    padding-bottom: 29px !important;
}
input.form-control.input-xl {
    padding-top: 29px;
    padding-bottom: 29px;
    font-size: 1.2rem;
}
@include media-breakpoint-up(xl) {
    input.form-control.input-xl.input-calculator {
        padding:29px 1.5px;
        font-size: 1rem;
    }
}
@include media-breakpoint-up(custom1600) {
    input.form-control.input-xl.input-calculator {
        padding:29px 5px;
        font-size: 1.2rem;
    }
}
@include media-breakpoint-up(custom1920) {
    input.form-control.input-xl.input-calculator {
        padding:29px 8px;
    }
}
.search-tariffs__container .btn {
    font-size: 1.2rem;
    padding: 16px 10px;
}
.calculator__container_main .btn, .search-tariffs__container .btn {
    padding: 20px 10px;
}
.calculator__container .dropdown-toggle::after {
    display: none;
}

.tariff__container, .tariff__card, .offer-form__card {
    border: 1px solid #BABFC7;
}
.cargo-type__container, .search-tariffs__container {
    width: 100%;
    @include make-container();
}

.cargo-type__row {
    @include make-row();
}

.cargo-type__col {
    @include make-col-ready();

    @include media-breakpoint-up(md) {
        @include make-col(12);
    }
    @include media-breakpoint-up(custom1600) {
        @include make-col(6);
    }
    @include media-breakpoint-up(custom2200) {
        @include make-col(4);
    }
}

.search-tariffs__col {
    @include make-col-ready();

    //@include media-breakpoint-up(xl) {
    //    @include make-col(4);
    //}
    //@include media-breakpoint-up(custom1920) {
    //    @include make-col(4);
    //
    //}
}

.search-tariffs__col_sm {
    @include make-col-ready();


}

@media (min-width: 360px) {
    .multiselect__placeholder {
        margin-bottom: 0px !important;
        padding-top: 0px !important;
    }
}

.calculator__title {
    font-size: 20px;
    margin-bottom: 70px;
    line-height: 1.5;
}

@media (min-width: 1600px) {
    .calculator__container_main {
        margin: 0 30px;
    }
}

@include media-breakpoint-up(lg) {
    .calculator__title {
        font-size: 26px;
    }
}

@include media-breakpoint-up(custom1920) {
    .calculator__title {
        font-size: 37px;
    }
}

.search-tariffs__container .multiselect-country .multiselect__content-wrapper,.search-tariffs__container .multiselect-city .multiselect__content-wrapper {
    margin-top: 10px;
}
.multiselect__content-wrapper {
    margin-top: 0;
}
.multiselect__select:before {
    border-style: unset !important;
}

.calculator__container_main .modal-book .btn, .calculator__container .modal .btn {
    font-size: 1rem !important;
    line-height: 1.5 !important;
    border-radius: 0.25rem !important;
    padding: 0.375rem 0.75rem !important;
}
.input-code-city {
    padding: 0.63rem 1.5rem;
}
.input-xl .fix-height {
    padding-top: 29px !important;
    padding-bottom: 29px !important;
}
.calculator__container_main .modal-book .btn, .calculator__container .modal .btn .btn-search {
    height: calc(2.75rem + 2px);
    padding: 0.6rem 0.75rem !important;
    white-space: nowrap;
}
.btn-sorting {
    padding: 0.75rem 0.5rem !important;
    font-size: 12px !important;
}
.text2 {
 font-size: 12px;
}
.text3 {
    font-size: 10px;
}
.multiselect.multiselect-city.form-control {
    border-bottom-right-radius: 4px !important;
    border-top-right-radius: 4px !important;
    padding: 0.63rem 0 !important;
}
.weight_kilogram{
    min-width: 115px;
}

input.form-control.input-xl.text-center.input-calculator{
    cursor: pointer;
    font-size: 14px;
}
@media (max-width: 1440px) {
    input.form-control.input-xl.text-center.input-calculator{
        font-size: 11px;
    }
}

.application-timer{
    font-size: 24px;
}
//для логотипов курьерских служб в карточке тарифов
.img-courier_name{
    object-fit: contain;
    height: 60px;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
  
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fadeInDown {
    animation: fadeInDown 0.5s ease-out;
}

@keyframes fadeOutUp {
    from {
        opacity: 1;
    }
  
    to {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
}
  
.fadeOutUp {
    animation: fadeOutUp 0.5s ease-in;
}

//прикрепить вверх калькулятор
@include media-breakpoint-up(lg) {
    .sticky {
        top: 6rem;
        margin-left: -30px;
        position: fixed;
        opacity: 1;
        background-color: #fff;
        z-index: 1020;
        border-bottom: 1px solid #80808059;
    }
}
@include media-breakpoint-up(xl) {
    .sticky {
        top: 7rem;
        right: 0;
        transition: none !important;
    }
     .menu-collapsed .sticky{
       left: 60px+30px;
       transition: 300ms ease all;
    }
    .menu-expanded  .sticky{
       left: 260px+30px;
       transition: 300ms ease all;
    }
    .col-xl-12{
        width:100%;
    }
}
@include media-breakpoint-up(custom1920) {
    .sticky {
        top: 6rem;
    }
}



//выпадающий список городов
.search-tariffs__container .multiselect-city .multiselect__content-wrapper {
    margin-left: -70px;
    width: -webkit-fill-available;
}

@include media-breakpoint-up(sm){
    .search-tariffs__container .multiselect-city .multiselect__content-wrapper {
        margin-left: -80px;
        width: -webkit-fill-available;
    }
}
@include media-breakpoint-up(xl) {
    .search-tariffs__container .multiselect-city .multiselect__content-wrapper {
        margin-left: -80px;
        width: max-content;
    }
}
@include media-breakpoint-up(lg) {
/*    .sticky button.btn-xs.add_book {
        display: none;
    }*/
}

.btn-pay_later {
    max-width: 378px;
}

.form-control {
    text-transform: uppercase;
}

.profile-password, .profile-password-confirmation, .form-control[type=password] {
    text-transform: none;
}

.wrrp-footer {
    padding: 0 5% 3%;
}

.wrrp-footer::after {
    content: '';
    display: block;
    clear: both;
}

.wrrp-footer .footer-label {
    float: right
}

.wrrp-footer .footer-label {
    width: 150px;
    text-align: center;
}

.wrrp-footer .footer-label img {
    width: 100%;
}

.wrrp-footer .footer-label .title {
    font-size: 12px;
    margin-top: 8px;
    line-height: 1.1;
}
