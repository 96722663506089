// ================================================================================================
//     File Name: content.scss
//     Description: Page content level SCSS for different screen size, layout and device.
//     ----------------------------------------------------------------------------------------------
//     Item Name: Chameleon Admin - Modern Bootstrap 4 WebApp & Dashboard HTML Template + UI Kit
//     Version: 1.2
//     Author: ThemeSelection
//     Author URL: https://themeselection.com/
// ================================================================================================

// Contest margin left for expanded & collapsed stats width & margin left
// ================================================================================================
html {
    font-size: 14px;
    height: 100%;
    width: 100%;
    body {
        height: 100%;
        &.fixed-navbar{
            padding-top: $navbar-height;
        }
        background-color: $content-bg;
        direction: $body-direction;
        a{
            color:$primary;
            &:hover{
                color: darken($primary,10);
            }
        }

        &.blank-page{
            .content-wrapper-before{
                height:0 !important;
            }
        }

        .content {
            padding: 0;
            position:relative;
            transition: 300ms ease all;
            backface-visibility: hidden;
            min-height: calc(100% - 32px);
            &.app-content{
                overflow: hidden;
            }
            .content-wrapper {
                padding: 2.2rem;
                .content-header-title{
                    font-weight: 700;
                    letter-spacing: 1px;
                    color: $white;

                }
                .content-header-right{
                    // margin-top: 6px;
                }
                .content-wrapper-before{
                    z-index: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 120px;
                    transition: 300ms ease all;
                }
            }
        }
        &.chat-application{
            .content {
                .content-wrapper {
                    .content-wrapper-before{
                        z-index: -1;
                    }
                }
            }
        }
        // Line awesome font size
        .la{
            font-size: 1.4rem;
        }
        //Remove left margin for 1 column layout
        &[data-col="1-column"] {
            &:not(.vertical-content-menu) {
                .content,
                .footer {
                    margin-left: 0px !important;
                }
            }
            &.horizontal-layout {
                .content,
                .footer {
                    margin: 0 auto !important;
                }
            }
            &.vertical-content-menu {
                .content-body {
                    margin-left: 0px !important;
                }
            }
        }
        &.boxed-layout {
            padding-left: 0;
            padding-right: 0;
        }
        &.bg-full-screen-image {
            background: url(../images/backgrounds/bg-18.jpg) no-repeat center center fixed;
            background-size: cover;
        }
        .pace {
            .pace-progress {
                background: $danger;
            }
        }

    }
}

/*
* Blank page
*/

.blank-page {
    .content-wrapper {
        padding: 0 !important;
        .flexbox-container {
            display: flex;
            align-items: center;
            height: 100vh;
        }
    }
}
.app-content.center-layout{
    overflow: hidden;
}

// Reduce the content padding for mobile screen
@include media-breakpoint-down(sm) {
    html {
        body {
            .content {
                .content-wrapper {
                    padding: 1rem;
                }
            }
            footer {
                text-align: center;
            }
        }
    }
}


/*
* Col 3 layout for detached and general type
*/

@include media-breakpoint-up(lg) {
    body {
        // Normal sidebar
        .content-right {
            width: calc(100% - #{$sidebar-width});
            float: right;
        }
        .content-left {
            width: calc(100% - #{$sidebar-width});
            float: left;
        }
        // Detached sidebar
        .content-detached {
            width: 100%;
            &.content-right {
                float: right;
                margin-left: -$sidebar-width;
                .content-body {
                    margin-left: $sidebar-width+20;
                }
            }
            &.content-left {
                float: left;
                margin-right: -$sidebar-width;
                .content-body {
                    margin-right: $sidebar-width+20;
                }
            }
        }
    }
    .sidebar-right.sidebar-sticky {
        float: right !important;
        margin-left: -$sidebar-width;
        width: $sidebar-width !important;
    }

    //Detached left sticky sidebar
   .sidebar-left.sidebar-sticky {
      float: left !important;
      margin-right: -$sidebar-width;
      width: $sidebar-width !important;
   }

    //left sticky sidebar
    [data-col="content-left-sidebar"] {
        .sticky-wrapper {
            float: left;
        }
    }
    .vertical-content-menu {
        .content {
            // Detached sidebar
            .sidebar-left {
                margin-left: ($content-menu-expanded-width + 28);
            }
            .content-detached {
                &.content-right {
                    float: right;
                    width: calc(100% - #{$sidebar-width});
                    margin-left: -$sidebar-width;
                    .content-body {
                        margin-left: ($sidebar-width - 5);
                    }
                }
                &.content-left {
                    float: left;
                    width: 100%;
                    margin-right: -$sidebar-width;
                    .content-body {
                        margin-right: $sidebar-width+20;
                    }
                }
            }
        }
    }
}

// Base font size and line height for extra large screen
@media screen
  and (min-device-width: 1441px)
  and (max-device-width: 2000px)   {
    p{
        font-size: 15px;
        line-height: 1.6;
        letter-spacing: 0.2px;
    }
}


// Chartist tooltip fix
.chartist-tooltip{
  top: 0;
}
