//  ===============================================================================================
//  File Name: horizontal.scss
//  Description: Horizontal layout SASS file for layout related changes only
//  ----------------------------------------------------------------------------------------------
//  Item Name: Chameleon Admin - Modern Bootstrap 4 WebApp & Dashboard HTML Template + UI Kit
//  Version: 1.2
//  Author: ThemeSelection
//  Author URL: https://themeselection.com/
// ================================================================================================

.horizontal-layout{
    .horizontal-menu-padding{
        .header-navbar {
            .navbar-container{
                padding: 0;
            }
        }
    }
    .header-navbar .navbar-container{
        height: auto;
        width: 100%;
    }
}